










import {Component, Vue} from 'vue-property-decorator';

@Component({})

export default class Breadcrumb extends Vue {

  get breadcrumb() {
    return this.$route.name;
  }

  private toggleClick() {
    this.$store.dispatch('setSidebar');
  }

};
