import Vue from 'vue'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css';

//全局修改默认配置
ElementUI.Dialog.props.closeOnClickModal.default = false;
ElementUI.Dialog.props.closeOnPressEscape.default = false;
ElementUI.Drawer.props.wrapperClosable.default = false;

Vue.use(ElementUI);
