import {getConfiguration} from '@/api/abp-configuration';
import {Module} from 'vuex';
import {RootState, AccountState} from '@/store/types';
import {assignAbp} from '@/utils/abp';
import Vue from 'vue'

const account: Module<AccountState, RootState> = {
  namespaced: true,
  state: {
    token: '',
    applicationConfiguration: {}
  },
  mutations: {
    SET_TOKEN(state: any, token: any) {
      state.token = token;
    },
    SET_CURRENT(state: AccountState, current: any) {
      state.applicationConfiguration = current;
    },
    SIGN_OUT(state: AccountState) {
      state.token = '';
      state.applicationConfiguration = {};
    },
  },
  actions: {
    setToken({commit}, token: any) {
      // 设置Cookie过期时间为一天
      const date = new Date();
      const dateDate = date.setDate(date.getDate() + 1);
      const newDate = new Date(dateDate);
      Vue.prototype.$abp.auth.setToken(token, newDate)
      commit('SET_TOKEN', token);
    },
    setCurrent({commit}) {
      return new Promise((resolve, reject) => {
        getConfiguration().then((res) => {
          commit('SET_CURRENT', res);
          assignAbp();
          resolve(res);
        }).catch((error) => {
          reject(error);
        });
      });
    },
    signOut({commit}) {
      return new Promise((resolve) => {
        // 后端退出登录
        Vue.prototype.$abp.auth.clearToken()
        commit('SIGN_OUT');
        resolve(0);
      });
    },
  }

};

export default account;
