import {RootState} from '@/store/types';

const getters = {
  token: (state: any) => state.account.token,
  applicationConfiguration: (state: RootState) => state.account.applicationConfiguration,
  routers: (state: any) => state.permission.routers,
  addRouters: (state: any) => state.permission.addRouters,
  sidebar: (state: any) => state.settings.sidebar,
  breadcrumb: (state: any) => state.settings.breadcrumb
};

export default getters;
