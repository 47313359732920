import request from '@/utils/request';

const url = `/identity/my-profile/change-password`;

export function changePasswordUser(currentPassword: string, newPassword: string): Promise<any> {
  return new Promise<any>((resolve, reject) => {
    request({
      url,
      method: 'post',
      data: {
        currentPassword,
        newPassword
      }
    }).then((res) => resolve(res.data)).catch((error) => reject(error));
  });
}
