





































import {Component, Vue} from 'vue-property-decorator';
import {resetRouter} from '@/router/index';
import ChangePassword from '@/components/changePassword.vue';

@Component({
  components: {
    ChangePassword
  }
})
export default class Header extends Vue {
  private visible = false;

  get currentUser() {
    if (!this.$store.state.account.applicationConfiguration.currentUser) {
      return ''
    }
    return this.$store.state.account.applicationConfiguration.currentUser.userName;
  }

  get currentPathArr() {
    return this.$store.state.permission.addRouters.filter((v: any) => (v.children && v.children.length > 0) && !v.hidden);
  }


  get routes() {
    return this.$router.options.routes;
  }

  get activeMenu() {
    const route = this.$route;
    const {meta, path} = route;
    const selectMenu = path.split('/')[1];
    if (meta) {
      if (meta.activeMenu) {
        return meta.activeMenu;
      }
    }
    this.$emit('select', selectMenu);
    return selectMenu;
  }


  public handleCommand(command: any) {
    if (command === 'logout') {
      this.logout();
    } else if (command === 'changePassword') {
      this.visible = true;
    }
  }


  public handleSelect(key: any, keyPath: any) {
    this.$emit('select', keyPath[0]);
  }


  public logout() {
    resetRouter();
    this.$store.dispatch('account/signOut').then(() => {
      location.reload()
    });
  }


  public closeDialog(v: boolean) {
    this.visible = v;
  }
};
