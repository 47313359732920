import {Module} from 'vuex';
import {RootState, SettingState} from '@/store/types';

const settings: Module<SettingState, RootState> = {
  namespaced: true,
  state: {
    sidebar: false,
    breadcrumb: undefined
  },
  mutations: {
    SET_SIDEBAR: (state) => {
      state.sidebar = !state.sidebar;
    },
    SET_BREADCRUMB(state, breadcrumb) {
      state.breadcrumb = breadcrumb;
    }
  },
  actions: {
    setSidebar({commit}) {
      commit('SET_SIDEBAR');
    },
    setBreadCrumb({commit}, breadcrumb) {
      commit('SET_BREADCRUMB', breadcrumb);
    }
  }

};

export default settings;
