// @ts-nocheck

import router from './router';
import store from '@/store/index';

import NProgress from 'nprogress';
import 'nprogress/nprogress.css';

import Vue from 'vue';

const whiteList = ['/login'];

NProgress.configure({showSpinner: false});

router.beforeEach(async (to, from, next) => {
  NProgress.start();
  if (to && to.meta.title) {
    document.title = to.meta.title;
  }

  if (to.name === 'logout') {
    next();
    NProgress.done();
  } else {
    // const token = store.state.account.token;
    if (Vue.prototype.$abp.currentUser.isAuthenticated) {
      if (to.path === '/login') {
        next({path: '/', replace: true});
      } else {
        next();
        NProgress.done();
      }
    } else if (whiteList.indexOf(to.path) !== -1) { // 在免登录白名单，直接进入
      next();
      NProgress.done();
    } else {
      const token = Vue.prototype.$abp.auth.getToken();
      if (token) {
        await store.dispatch('account/setCurrent');
        store.dispatch('permission/GenerateRoutes', {
          apis: store.state.account.applicationConfiguration.auth.grantedPolicies
        }).then((addRouters) => {
          // 没有添加过才添加
          if (router.options.routes.length <= 1) {
            router.options.routes = router.options.routes.concat(addRouters);
            router.addRoutes(router.options.routes);
          }
        });
        next();
        NProgress.done();
      } else {
        next(`/login`); // 否则全部重定向到登录页
        NProgress.done();
      }
    }
  }

});

router.afterEach(() => {
  NProgress.done();
});
