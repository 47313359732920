// import store from '@/store/index';
import Vue from 'vue';
import {p_identity, p_project} from './permission-define';

/**
 * TODO 检查用户是否有按钮操作权限
 */
export default function checkPermission(value: string) {
  if (value) {
    let default_val = p_project;
    if (value.indexOf('Users') >= 0) {
      default_val = p_identity;
    }
    return Vue.prototype.$abp.auth.isGranted(default_val + value);
  } else {
    return false;
  }
}
