








import {Component, Vue} from 'vue-property-decorator';

@Component({})

export default class AppMain extends Vue {
  get key() {
    return this.$route.path;
  }
};
