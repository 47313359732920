























import {Component, Prop, Vue} from 'vue-property-decorator';
import {changePasswordUser} from '@/api/profile';

@Component({})
export default class changePassword extends Vue {
;
  @Prop({required: true}) private visible!: boolean;

  private dataForm = {
    password: '',
    newPassword: '',
    confirmNewPassword: ''
  };

  private rules = {
    password: [{required: true, message: '当前密码不能为空', trigger: 'blur'}],
    newPassword: [{required: true, message: '新密码不能为空', trigger: 'blur'}],
    confirmNewPassword: [
      {required: true, message: '确认新密码不能为空', trigger: 'blur'},
      {validator: this.validatePass, trigger: 'blur'}
    ]
  };

  public async changePassword() {
    (this.$refs.dataForm as any).validate(async (valid: any) => {
      if (valid) {
        const result = await changePasswordUser(this.dataForm.password, this.dataForm.newPassword);
        console.log(result);
        if (!result) {
          this.$message({
            message: '修改成功',
            type: 'success',
            onClose: () => {
              this.closeDialog();
              this.logout();
            }
          });
        }
      }
    });
  }

  private validatePass(rule: any, value: any, callback: any) {
    if (value === '') {
      callback(new Error('请再次输入新密码'));
    } else if (value !== this.dataForm.newPassword) {
      callback(new Error('两次输入新密码不一致!'));
    } else {
      callback();
    }
  }
  private closeDialog() {
    this.$emit('closeDialog', false);
  }

  private logout() {
    this.$emit('logout');
  }

};
