import request from '@/utils/request';

const url = `/abp/application-configuration`;

export function getConfiguration(): Promise<any> {
  return new Promise<any>((resolve, reject) => {
    request({
      url,
      method: 'get'
    }).then((res) => resolve(res.data)).catch((err) => reject(err));
  });
}
