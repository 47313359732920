





















// @ts-nocheck
import {Component, Prop, Vue} from 'vue-property-decorator';
import path from 'path';

@Component({})
export default class NavItem extends Vue {
  @Prop({required: true}) private item!: object;
  @Prop({required: true}) private basePath!: string;


  private onlyOneChild: any;

  private hasOnlyOneShowChild(children = [], parent: any) {
    const showingChildren = children.filter((item) => {
      if (item.hidden) {
        return false;
      } else {
        this.onlyOneChild = item;
        return true;
      }
    });

    if (showingChildren.length === 1) {
      return true;
    }

    if (showingChildren.length === 0) {
      this.onlyOneChild = {...parent, path: '', noShowingChildren: true};
      return true;
    }


    return false;
  }

  private resolvePath(routePath: any) {
    return path.resolve(this.basePath, routePath);
  }


};
