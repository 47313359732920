

















import {Component, Vue} from 'vue-property-decorator';
import NavBar from './components/NavBar.vue';
import Header from './components/Header.vue';
import AppMain from './components/AppMain.vue';
import Breadcrumb from './components/Breadcrumb.vue';


@Component({
  components: {
    Header,
    NavBar,
    AppMain,
    Breadcrumb
  }
})
export default class Index extends Vue {
  private menuActiveName = 'home';

  private selectMenu(name: string) {
    this.menuActiveName = name;
  }
};
