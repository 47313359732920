import Vue from 'vue';
import Vuex, {StoreOptions} from 'vuex';

import {RootState} from './types';
import account from './modules/account';
import permission from './modules/permission';
import settings from './modules/settings';
import getters from './getters';

Vue.use(Vuex);

const store: StoreOptions<RootState> = {
  modules: {
    account,
    permission,
    settings
  },
  getters
};

export default new Vuex.Store<RootState>(store);
