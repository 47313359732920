import {asyncRouterMap, constantRouterMap} from '@/router/index';

import {Module} from 'vuex';
import {RootState, PermissionState} from '@/store/types';

function hasPermission(apis: any, route: any) {
  if (route.meta && route.meta.api) {
    return apis[route.meta.api];
  } else {
    return true;
  }
}


/**
 * 递归过滤异步路由表，返回符合用户角色权限的路由表
 * @param routes asyncRouterMap
 * @param apis
 */
function filterAsyncRouter(routes: any, apis: any) {
  const res = [];

  for (const route of routes) {
    const tmp = {...route};
    if (hasPermission(apis, tmp)) {
      if (tmp.children) {
        tmp.children = filterAsyncRouter(tmp.children, apis);
      }
      // TODO 无下级不加载父级路由
      if (tmp.children <= 0) {
        continue;
      }
      res.push(tmp);
    }
  }

  return res;
}


const permission: Module<PermissionState, RootState> = {
  namespaced: true,
  state: {
    routers: [],
    addRouters: []
  },
  mutations: {
    SET_ROUTERS: (state, routers) => {
      state.addRouters = routers;
      state.routers = constantRouterMap.concat(routers);
    }
  },
  actions: {
    GenerateRoutes({commit}, {apis}) {
      return new Promise((resolve) => {
        const accessedRouters = filterAsyncRouter(asyncRouterMap, apis);
        commit('SET_ROUTERS', accessedRouters);
        resolve(accessedRouters);
      });
    }
  }
};

export default permission;
