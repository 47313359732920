// @ts-nocheck

import Vue from 'vue';
import generateAbp from './abp-package';
import applicationConfiguration from './application-configuration.json';
import store from '../store';

let abp = generateAbp(applicationConfiguration);

export default abp;

export function assignAbp() {
  const serverApplicationConfiguration = store.getters.applicationConfiguration;
  abp = generateAbp(serverApplicationConfiguration);
  Vue.prototype.$abp = abp;
}
