import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';

Vue.config.productionTip = false;

import abp from './utils/abp';

Vue.prototype.$abp = abp;

import checkPermission from './utils/permission';

Vue.prototype.$checkPermission = checkPermission;

import './plugins/element-ui';
import './permission';

import axios from 'axios';
import request from './utils/request'

async function mountVue() {
  const setting_url = `/appsettings.json`
  const res = await axios.get(setting_url);

  Object.defineProperty(Vue.prototype, '$appsettings', {
    get() {
      return res.data
    }
  });

  request.defaults.baseURL = res.data.ServerUrl + res.data.BaseApi

  const antiForgeryTokenRes = await request('/SetCsrfCookie');
  Vue.prototype.$abp.utils.setCookieValue(
    Vue.prototype.$abp.security.antiForgery.tokenCookieName,
    antiForgeryTokenRes.data.antiForgeryToken
  );

  new Vue({
    router,
    store,
    render: (h) => h(App),
  }).$mount('#app');
}

mountVue()
