// @ts-nocheck

import Vue from 'vue';
import VueRouter, {RouteConfig} from 'vue-router';
import layout from '../layout/index.vue';
import TemplateMain from '@/views/TemplateMain.vue';

Vue.use(VueRouter);

const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err: any) => err);
};

// 所有权限通用路由表
// 如首页和登录页和一些不用权限的公用页面
export const constantRouterMap = [
  {
    path: '/login',
    name: 'login',
    hidden: true,
    component: () => import('../views/Login.vue')
  }
];

export const asyncRouterMap = [
  {
    path: '/',
    component: layout,
    redirect: '/home',
    hidden: true,
    name: '首页',
    meta: {
      icon: 'el-icon-s-home',
    },
    children: [
      {
        name: '首页',
        path: 'home',
        component: () => import('../views/Home.vue'),
        meta: {
          icon: 'el-icon-s-home',
          api: ''
        }
      }
    ]
  },
  {
    path: '/course',
    component: layout,
    name: '课程管理',
    mata: {
      icon: '',
      api: ''
    },
    alwaysShow: false,
    redirect: '/course/management/list',
    children: [
      {
        path: '/course/management',
        name: '课程管理',
        meta: {
          icon: 'el-icon-s-order'
        },
        component: TemplateMain,
        children: [
          {
            path: 'list',
            name: '团课管理',
            component: () => import('../views/course/Index.vue'),
            meta: {
              icon: 'el-icon-s-order',
              api: 'KeepFitYC.Course'
            }
          },
          {
            path: 'pt',
            name: '私教管理',
            component: () => import('../views/course/PersonalTraining.vue'),
            meta: {
              icon: 'el-icon-s-order',
              api: 'KeepFitYC.Course'
            }
          },
          {
            path: 'coaches',
            name: '教练管理',
            component: () => import('../views/course/Coach.vue'),
            meta: {
              icon: 'el-icon-s-custom',
              api: 'KeepFitYC.Coach'
            }
          },
          {
            path: 'orders',
            name: '课程记录',
            component: () => import('../views/course/CourseOrder.vue'),
            meta: {
              icon: 'el-icon-s-order',
              api: ''
            }
          }
        ]
      }
    ]
  },
  {
    path: '/member',
    component: layout,
    name: '会员管理',
    mata: {
      icon: '',
      api: ''
    },
    alwaysShow: false,
    redirect: '/member/management/ship-card',
    children: [
      {
        path: '/member/management',
        name: '会员管理',
        meta: {
          icon: 'el-icon-s-custom'
        },
        component: TemplateMain,
        children: [
          {
            path: 'list',
            name: '会员列表',
            component: () => import('../views/member/Index.vue'),
            meta: {
              icon: '',
              api: ''
            }
          },
          {
            path: 'record-card',
            name: '购卡记录',
            meta: {
              icon: 'el-icon-s-order',
              api: ''
            },
            component: () => import('../views/member/CardRecord.vue'),
          },
          {
            path: 'ship-card',
            name: '会员卡列表',
            meta: {
              icon: 'el-icon-postcard',
              api: 'KeepFitYC.ShipCard'
            },
            component: () => import('../views/member/ShipCard.vue'),
          },
          // {
          //   path: 'coupon',
          //   name: '优惠劵列表',
          //   meta: {
          //     icon: 'el-icon-s-ticket',
          //     api: 'KeepFitYC.Coupon'
          //   },
          //   component: () => import('../views/member/Coupon.vue'),
          // }
          {
            path: 'assessment',
            name: '用户基本信息管理',
            meta: {
              icon: 'el-icon-takeaway-box',
              api: 'KeepFitYC.MemberShipAssessment'
            },
            component: () => import('../views/member/AssessmentInfo.vue')
          }
        ]
      }
    ]
  },
  {
    path: '/training-library',
    name: '训练项目库管理',
    meta: {
      icon: ''
    },
    component: layout,
    children: [
      {
        path: '/training-library/exercise-prescription',
        name: '运动处方管理',
        meta: {
          icon: 'el-icon-tickets',
          api: 'KeepFitYC.ExercisePrescription'
        },
        component: () => import('../views/prescription/exercise-prescription.vue')
      },
      {
        path: '/training-library/training-action',
        name: '训练动作库',
        meta: {
          icon: 'el-icon-bicycle',
          api: 'KeepFitYC.TrainingAction'
        },
        component: () => import('../views/prescription/training-action.vue')
      },
      {
        path: '/training-library/type',
        name: '运动训练类型',
        meta: {
          icon: 'el-icon-set-up',
          api: 'KeepFitYC.TrainingType'
        },
        component: () => import('../views/prescription/training-type.vue')
      }
    ]
  },
  {
    path: '/standard-data',
    name: '标准数据管理',
    meta: {
      icon: ''
    },
    component: layout,
    children: [
      {
        path: '/standard-data/bmi',
        name: 'BMI库',
        meta: {
          icon: 'el-icon-takeaway-box',
          api: 'KeepFitYC.Bmi'
        },
        component: () => import('../views/prescription/bmi.vue')
      },
      {
        path: '/standard-data/whr',
        name: 'WHR库',
        meta: {
          icon: 'el-icon-wind-power',
          api: 'KeepFitYC.Whr'
        },
        component: () => import('../views/prescription/whr.vue')
      },
      {
        path: '/standard-data/fat-percentage',
        name: '体脂率库',
        meta: {
          icon: 'el-icon-pie-chart',
          api: 'KeepFitYC.FatPercentage'
        },
        component: () => import('../views/prescription/fat-percentage.vue')
      },
      {
        path: '/standard-data/muscle-site',
        name: '部位管理',
        meta: {
          icon: 'el-icon-eleme',
          api: 'KeepFitYC.MuscleSite'
        },
        component: () => import('../views/prescription/muscle-site.vue')
      },
      {
        path: '/standard-data/motion-frequency',
        name: '运动频率管理',
        meta: {
          icon: 'el-icon-timer',
          api: 'KeepFitYC.MotionFrequency'
        },
        component: () => import('../views/prescription/motion-frequency.vue')
      }
    ]
  },
  {
    path: '/evaluation',
    name: '评估管理',
    meta: {
      icon: ''
    },
    component: layout,
    children: [
      {
        path: '/evaluation/selfAssessment',
        name: '自我评估管理',
        meta: {
          icon: 'el-icon-takeaway-box',
          api: 'KeepFitYC.MemberShipAssessment'
        },
        component: () => import('../views/evaluationManagement/SelfAssessment.vue')
      },
      {
        path: '/evaluation/conclusion',
        name: '评估结论管理',
        meta: {
          icon: 'el-icon-c-scale-to-original',
          api: 'KeepFitYC.MemberShipAssessment'
        },
        component: () => import('../views/evaluationManagement/Conclusion.vue')
      }
    ]
  },
  {
    path: '/gym',
    name: '健身房管理',
    meta: {
      icon: ''
    },
    component: layout,
    children: [
      {
        path: '/gym/shops',
        name: '健身房管理',
        component: () => import('../views/gym/ShopManagement.vue'),
        meta: {
          icon: 'el-icon-office-building',
          api: 'KeepFitYC.ShopManagement'
        }
      },
      {
        path: '/gym/sports-equipment',
        name: '训练器械管理',
        component: () => import('../views/gym/sports-equipment.vue'),
        meta: {
          icon: 'el-icon-basketball',
          api: 'KeepFitYC.SportsEquipment'
        }
      }
    ]
  },
  {
    path: '/system',
    name: '系统设置',
    meta: {
      icon: 'el-icon-setting'
    },
    component: layout,
    alwaysShow: false,
    redirect: '/system/identity/users',
    children: [
      {
        path: '/system/identity',
        name: '用户管理',
        component: TemplateMain,
        meta: {
          icon: 'el-icon-user'
        },
        children: [
          {
            path: 'users',
            name: '用户列表',
            component: () => import('../views/identity/User.vue'),
            meta: {
              icon: 'el-icon-user',
              api: 'AbpIdentity.Users'
            }
          }
        ]
      },
      {
        path: '/system',
        name: '系统管理',
        component: TemplateMain,
        meta: {
          icon: 'el-icon-odometer'
        },
        children: [
          {
            path: 'equipment',
            name: '设备管理',
            component: () => import('../views/system/Equipment.vue'),
            meta: {
              icon: 'el-icon-odometer',
              api: 'KeepFitYC.Equipment'
            }
          },
          {
            path: 'ad',
            name: '广告管理',
            component: () => import('../views/system/AdManagement.vue'),
            meta: {
              icon: '',
              api: 'KeepFitYC.AdManagement'
            }
          }
        ]
      }
    ]
  }
];

const createRouter = () => new VueRouter({
  // mode: 'history', // require service support
  scrollBehavior: () => ({y: 0}),
  routes: constantRouterMap
});

const router = createRouter();

export function resetRouter() {
  const newRouter = createRouter();
  router.matcher = newRouter.matcher; // reset router
}

export default router;
